import React, { useState, useEffect } from 'react';
import FileUploader from './FileUploader';
import GanttChart from './GanttChart';
import { Task } from 'gantt-task-react';


function Dsn() {
  const [data, setData] = useState<Task[]>([]);

  useEffect(() => {
    // Handle initial data loading if necessary
  }, []);

  const handleFileUploaded = (fetchedData: Task[]) => {
    setData(fetchedData);
  };

  return (
    <div className="App">
      <FileUploader onFileUploaded={handleFileUploaded} />
      {data.length > 0 && <GanttChart data={data} />}
    </div>
  );
}

export default Dsn;
