import React, { useState, useEffect } from "react";

import { marked } from "marked";
import Container from "@mui/material/Container";

import fetcher from "./fetcher";

export interface DocMarkdownProps {
  fileName: string;
}

const DocMarkdown = (props: DocMarkdownProps) => {
  const [data, setData] = useState("");
  console.log("Building DocMarkdown", props.fileName);
  useEffect(() => {
    fetcher(`/static/media/${props.fileName}.md`)
      .then((response) => response.blob())
      .then((blob) => blob.text())
      .then((text) => {
        setData(marked.parse(text, { sanitize: true }));
      })
      .catch((err) => {
        console.log("md load", err);
      });
  }, [props]);

  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: data }}></div>
    </Container>
  );
};

export default DocMarkdown;
