import React, { useState } from "react";

let states: Record<string, any> = {};
export const useRegister = (name:string, init:any) => {
    
    states[name] = useState(init);
    return states[name];
}

export const getState = (name:string) => {
    return states[name][0];
}

export const getStateSetter = (name:string) => {
    return states[name][1];
}


export const setState = (name:string, newState:any) => {
    getStateSetter(name)(newState);
}
