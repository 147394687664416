import React, { useEffect, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { Amplify } from "aws-amplify";

const SharedAuthenticator = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    fetch("/static/cognito.json", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        Amplify.configure({
          Auth: {
            json,
          },
        });
        setLoaded(true);
      });
  }, []);
  return (
    <Authenticator loginMechanisms={["username", "email"]}>
      {children}
    </Authenticator>
  );
};

export default SharedAuthenticator;
