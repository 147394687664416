import React, { ReactNode, useEffect, useState } from "react";
import { Fade, Slide } from "@mui/material";

interface FadeOnVisibleProps {
  children: React.ReactElement;
  kind: "fade" | "slide";
  timeout?: number;
  [key: string]: any;
}

const FadeOnVisible: React.FC<FadeOnVisibleProps> = ({
  children,
  kind,
  timeout = 2000,
  ...propsTransition
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, setRef] = useState<Element | null>(null);

  useEffect(() => {
    if (ref) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        }
      );
      observer.observe(ref);
      return () => {
        observer.unobserve(ref);
      };
    }
    return undefined;
  }, [ref]);

  const Component = kind === "slide" ? Slide : Fade;
  const sign = propsTransition?.direction === "right" ? "-" : "+";
  if (kind === "slide") {
    return (
      <div
        ref={setRef}
        style={{
          transform: isVisible ? "translateX(0)" : `translateX(${sign}10rem)`,
          transition: `transform ${timeout}ms`,
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <div ref={setRef}>
      <Component in={isVisible} timeout={timeout} {...propsTransition}>
        {children}
      </Component>
    </div>
  );
};

export default FadeOnVisible;
