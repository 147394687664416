import React from "react";
import { Container, Grid, Box, IconButton } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
const TwitterX = require("./twitter-x.png");

const Footer = () => {
  const theme = useTheme();
  return (
    <footer>
      <Box py={4} sx={{ backgroundColor: theme.palette.backgrounds.dark }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                justifyContent="center"
                sx={{ justifyContent: "flex-start" }}
              >
                <IconButton
                  color="inherit"
                  href="https://twitter.com/MatchIT_guru"
                >
                  <img src={TwitterX} alt="Logo" style={{ height: "20px" }} />
                </IconButton>
                <IconButton
                  color="inherit"
                  href="https://www.linkedin.com/company/matchittech"
                >
                  <LinkedIn />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} container spacing={4}>
              <Grid item display="flex">
                <Link
                  to="/terms-of-service"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Terms
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Privacy
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="/mention-legale"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Mention Legale
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
