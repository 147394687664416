const fetcher = (
  url: string,
  method: string = "GET",
  data: Record<string, any> | undefined = undefined
): Promise<Response> => {
  return fetch(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : null,
  });
};

export default fetcher;
