import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChatIcon from "@mui/icons-material/Chat";
import DescriptionIcon from "@mui/icons-material/Description";
import SecurityIcon from "@mui/icons-material/Security";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { useTheme } from "@mui/material/styles";
import img_typing from "./undraw_typing_re_d4sq.svg";
import img_voting from "./undraw_voting_nvu7.svg";
import img_bot from "./undraw_chat_bot_re_e2gj.svg";
import TransitionOnVisible from "./TransitionOnVisible";
import { getStateSetter } from "./Context";
import { snackMessage, SnackProps } from "./utils";

function LandingPage() {
  const theme = useTheme();

  return (
    <Box px={2}>
      <ParallaxProvider>
        <Paper elevation={24} sx={{ minHeight: "80vh" }}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={4}
            py={3}
            sx={{}}
          >
            <Grid
              item
              md={12}
              lg={6}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    "&::first-letter": {
                      fontSize: "1.1em",
                    },
                    fontSize: "5rem",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                    WebkitTextStrokeWidth: "2px",
                  }}
                >
                  Faites parler vos DSN.
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Poser vos questions en français
                </Typography>
                <Typography
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  pas en sql*]@àç_è-('
                </Typography>
              </Grid>
              <Grid item my={3}>
                <Button variant="contained" component="a" href="/dsn">
                  Aujourd'hui, c'est gratuit ;)
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              lg={6}
              container
              alignItems="center"
              justifyContent="center"
            >
              <Fade in={true} timeout={2000}>
                <img
                  src={img_typing}
                  alt="matching people"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Fade>
            </Grid>
          </Grid>
        </Paper>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={8}
          py={12}
          sx={{ backgroundColor: theme.palette.backgrounds.main }}
        >
          <Grid
            item
            md={12}
            lg={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <TransitionOnVisible
                kind="slide"
                timeout={1500}
                direction="right"
              >
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <ChatIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="🗣️ Français au lieu de SQL/Excel"
                      secondary="Simplifiez vos demandes, parlez en français."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DescriptionIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="🚫 Oubliez les feuilles Excel"
                      secondary="Fini les documents compliqués, dupliqués, perdus ou à renvoyer."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <SecurityIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="🔒 Données sécurisées"
                      secondary="Pas de partage de données avec l'IA, sécurité et magie garanties."
                    />
                  </ListItem>
                </List>
              </TransitionOnVisible>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            lg={6}
            container
            alignItems="center"
            justifyContent="center"
          >
            <TransitionOnVisible kind="fade">
              <img
                src={img_voting}
                alt="matching people"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </TransitionOnVisible>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={8}
          py={12}
          sx={{ backgroundColor: theme.palette.backgrounds.light }}
        >
          <Grid
            item
            md={12}
            lg={6}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <TransitionOnVisible kind="fade">
              <img
                src={img_bot}
                alt="matching people"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </TransitionOnVisible>
          </Grid>
          <Grid item>
            <TransitionOnVisible kind="slide" timeout={1500} direction="left">
              <Box>
                <Typography variant="h3">Looking for a new hire?</Typography>
                <Box mt={2}>
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    🎯 Match with candidates that fit, no résumé decoding
                  </Typography>
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    🤝 Direct connections with pre-qualified talent
                  </Typography>
                  <Typography sx={{ fontSize: "1.5rem" }}>
                    🚀 Align offers with candidate ambitions for long-term
                    success
                  </Typography>
                </Box>
              </Box>
            </TransitionOnVisible>
          </Grid>
        </Grid>
      </ParallaxProvider>
    </Box>
  );
}

export default LandingPage;
