import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import { Task } from 'gantt-task-react';


interface FileData {
  presignedUrl: string;
}

function FileUploader({ onFileUploaded }: { onFileUploaded: (data: Task[]) => void }) {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (Array.isArray(event.target.files) && event.target.files.length > 0) {
	  setFile(event.target.files[0]);
      }
  };

  const handleUpload = async () => {
    if (!file) {
      return; // Handle no file selected case
    }

    // Fetch the pre-signed URL for the S3 upload
    const response = await fetch('your-presigned-url-endpoint', {
      method: 'POST',
      body: JSON.stringify({ filename: file.name }),
    });

    const { presignedUrl } = await response.json() as FileData;

    // Upload the file to S3 using the pre-signed URL
    await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
    });

    // Fetch data from uploaded file or API endpoint
    const fetchedData: Task[] = await fetch('your-data-endpoint').then((response) => response.json());

    onFileUploaded(fetchedData); // Pass fetched data to parent component
  };

  return (
    <div>
      <Input type="file" onChange={handleFileChange} />
      <Button onClick={handleUpload}>Upload</Button>
    </div>
  );
}

export default FileUploader;
